$(document).ready(function () {
	animationLoader();
	setBackgroundElement();
	// header
	mappingMenu();
	// swiper
	swiperInit();
	// Header
	header.init();
	page_Product.init();
	menuScrollGt();
});
function menuScrollGt() {
	$(".product-pumping-detail").each(function (index) {
		$(this).attr("id", "sec" + index);
	});
	$(".tab-scroll-product li").each(function (index) {
		$(this).attr("data-scroll", "sec" + index);
	});
	if ($(window).width() < 1280) {
		$(".tab-scroll-product ul li").appendTo(
			".category-page-gt .list-menu-page"
		);
	}
	let heightHeader = $("header").outerHeight();
	if ($(window).width() >= 1280) {
		$(".tab-scroll-product").sticky({
			topSpacing: 57,
			zIndex: 999,
		});
		setTimeout(() => {
			$(".tab-scroll-product").activescroll({
				scroll: "scroll",
				active: "active",
				offset: 150,
			});
		}, 1500);
	} else {
		$(".category-page-gt").activescroll({
			scroll: "scroll",
			active: "active",
			offset: 60,
		});
	}
	$(".category-page-gt .title-header").click(function () {
		$(this).parent().toggleClass("active");
	});
	let heightBanner = $(".banner__pages").outerHeight();
	$(window).scroll(function () {
		let checkScroll = $(this).scrollTop();
		if (checkScroll > heightBanner) {
			$(".category-page-gt").addClass("show-p");
		} else {
			$(".category-page-gt").removeClass("show-p");
		}
	});
}
/*==================== Set Function Page Product ====================*/
let page_Product = {
	clickViewMore: function () {
		var $parent = $(".js-show-more"),
			$items = $parent.find(".item-show-more"),
			$loadMoreButton = $(".js-show-more .btn-load-more"),
			numberItems = $parent.data("number-item"), // số lượng item ban đầu hiển thị
			maxItems = $parent.data("once-show"); // Số lượng item mỗi lần nhấn
		var hiddenClass = "visually-hidden";
		$.each($items, function (idx, item) {
			if (idx > numberItems - 1) {
				$(this).addClass(hiddenClass);
			}
		});
		if ($("." + hiddenClass).length === 0) {
			$loadMoreButton.hide();
		}
		$loadMoreButton.on("click", function (e) {
			console.log("run");
			$("." + hiddenClass).each(function (idx, item) {
				if (idx < maxItems) {
					$(this).slideDown();
					$(this).removeClass(hiddenClass);
				}
				// kill button if no more to show
				if ($("." + hiddenClass).length === 0) {
					$loadMoreButton.hide();
				}
			});
		});
	},
	filter: function () {
		$("select[multiple]").each(function (index) {
			let data_placeholder = $(this).attr("data-placeholder");
			$(this).multiselect({
				maxHeight: 242,
				texts: {
					placeholder: data_placeholder,
				},
			});
		});
	},
	toggle_item: function () {
		$(".toggle-item").on("click", function () {
			$(this)
				.toggleClass("active")
				.siblings()
				.removeClass("active")
				.not()
				.find(".content")
				.slideUp();
			$(this).find(".content").slideToggle();
		});
	},
	init: function () {
		page_Product.clickViewMore();
		page_Product.filter();
		page_Product.toggle_item();
	},
};
/*==================== Set Background Element ====================*/
function setBackgroundElement() {
	$("[setBackground]").each(function () {
		var background = $(this).attr("setBackground");
		$(this).css({
			"background-size": "cover",
			"background-position": "center center",
			"background-image": "url(" + background + ")",
		});
	});
}
/*==================== Focus search ====================*/
function focusSearch() {
	$(".searchbox input")
		.focus(function () {
			$(this).parent().removeClass("notFocus");
			$(this).parent().addClass("focus");
		})
		.blur(function () {
			$(this).parent().removeClass("focus");
			$(this).parent().addClass("notFocus");
		});
}
/*==================== Mapping Menu ====================*/
function mappingMenu() {
	let ww = $(window).width();
	if (ww < 1280) {
		$("header .header-right .main-menu").appendTo(".menu-mobile-main");
	}
}
/*==================== Animation loader  ====================*/
function animationLoader(e) {
	$("body").addClass("loaded");
}
/*==================== JS Other ====================*/
function height(el) {
	var height = 0;
	$(el).each(function () {
		var thisHeight = $(this).height();
		if (thisHeight > height) {
			height = thisHeight;
		}
		setTimeout(() => {
			$(el).height(height);
		}, 100);
	});
}

let header = {
	scrollActive: function () {
		let height = $("header").height();
		if ($(window).width() >= 1280) {
			if ($(window).scrollTop() > height) {
				$(".header-1").addClass("isClose");
				$(".header-2").addClass("isOpen");
			} else {
				$(".header-1").removeClass("isClose");
				$(".header-2").removeClass("isOpen");
			}
		}
	},
	search: function () {
		$(".header-search").on("click", function () {
			$(".wrap-search-main").toggleClass("active");
		});
		$(".wrap-search-main .close").click(function () {
			$(".wrap-search-main").removeClass("active");
		});
	},
	cloneHeader: function () {
		if ($(window).width() >= 1280) {
			$("header")
				.clone()
				.addClass("header-2")
				.removeClass("header-1")
				.insertAfter("header");
		}
	},
	hambuger: function () {
		$(".hambuger").click(function () {
			$(this).toggleClass("active");
			$("header").addClass("isClose");
			$(".menu-mobile-main").toggleClass("isOpen");
			$(".overlay").toggleClass("active");
		});
		$(".menu-mobile-main .close").on("click", function () {
			$(".hambuger").removeClass("active");
			$(".menu-mobile-main").removeClass("isOpen");
			$(this).removeClass("active");
			$(".overlay").removeClass("active");
			$("header").removeClass("isClose");
		});
		$(".overlay").on("click", function () {
			$(".hambuger").removeClass("active");
			$(".menu-mobile-main").removeClass("isOpen");
			$(this).removeClass("active");
			$("header").removeClass("isClose");
		});
	},
	header_space: function () {
		let heightHeader = $("header").outerHeight();
		$("main").css("padding-top", heightHeader);
	},
	clickDropdownMenu: function () {
		if ($(window).width() < 1280) {
			$(".menu-mobile-main li").each(function () {
				if ($(this).hasClass("dropdown")) {
					$(this)
						.find(".menu-title")
						.append(
							'<div class="icon-drop-down"><em class="material-icons">expand_more</em></div>'
						);
				}
			});
			$(".icon-drop-down").click(function () {
				$(this).parent().toggleClass("active");
				$(this).parent().next().slideToggle();
				$(this).closest("li").siblings().find(".menu-dropdown").slideUp();
				$(this)
					.closest("li")
					.siblings()
					.find(".menu-title")
					.removeClass("active");
			});
		}
	},
	init: function () {
		header.cloneHeader();
		header.search();
		header.hambuger();
		header.header_space();
		header.clickDropdownMenu();
	},
};
/*==================== Swiper ====================*/
function swiperInit() {
	var bannerHome = new Swiper(".banner-home .swiper-container", {
		slidesPerView: 1,
		loop: true,
		autoplay: {
			delay: 5000,
		},
		speed: 300,
	});
	var section_home_4 = new Swiper(".section-home-4 .swiper-container", {
		slidesPerView: 2,
		spaceBetween: 15,
		speed: 300,
		navigation: {
			nextEl: ".section-home-4  .btn-next",
			prevEl: ".section-home-4  .btn-prev",
		},
		breakpoints: {
			575: {
				spaceBetween: 30,
				slidesPerView: 2,
			},
			1024: {
				spaceBetween: 30,
				slidesPerView: 3,
			},
			1280: {
				spaceBetween: 30,
				slidesPerView: 4,
			},
		},
	});
	var Other_News = new Swiper(".page-news-detail.other .swiper-container", {
		slidesPerView: 2,
		spaceBetween: 15,
		speed: 300,
		navigation: {
			nextEl: ".page-news-detail.other  .btn-next",
			prevEl: ".page-news-detail.other  .btn-prev",
		},
		breakpoints: {
			575: {
				spaceBetween: 30,
				slidesPerView: 2,
			},
			1024: {
				spaceBetween: 30,
				slidesPerView: 3,
			},
			1280: {
				spaceBetween: 30,
				slidesPerView: 4,
			},
		},
	});
	var section_home_5 = new Swiper(".section-home-5 .swiper-container", {
		slidesPerView: 2,
		spaceBetween: 15,
		speed: 300,
		navigation: {
			nextEl: ".section-home-5  .btn-next",
			prevEl: ".section-home-5  .btn-prev",
		},
		breakpoints: {
			575: {
				spaceBetween: 30,
				slidesPerView: 2,
			},
			1024: {
				spaceBetween: 30,
				slidesPerView: 4,
			},
			1280: {
				spaceBetween: 20,
				slidesPerView: 6,
			},
		},
	});
	var industries_2 = new Swiper(
		".industries-mining-detail.section-2 .swiper-container",
		{
			slidesPerView: 1.4,
			slidesPerColumn: 1,
			slidesPerGroup: 1,
			speed: 300,
			slidesPerColumnFill: "row",
			autoplay: {
				delay: 2500,
			},
			spaceBetween: 10,
			breakpoints: {
				575: {
					spaceBetween: 20,
					slidesPerView: 2,
					slidesPerColumn: 2,
				},
				1024: {
					spaceBetween: 30,
					slidesPerView: 3,
					slidesPerColumn: 2,
				},
			},
		}
	);
	var indutries_3 = new Swiper(
		".industries-mining-detail.section-3 .swiper-container ",
		{
			effect: "coverflow",
			grabCursor: true,
			centeredSlides: true,
			loop: true,
			coverflowEffect: {
				rotate: 0,
				stretch: 10,
				depth: 130,
				modifier: 1,
				slideShadows: false,
			},
			slidesPerView: "auto",
			breakpoints: {
				768: {
					coverflowEffect: {
						rotate: 0,
						stretch: 350,
						depth: 260,
						modifier: 1,
						slideShadows: false,
					},
				},
				1024: {
					coverflowEffect: {
						rotate: 0,
						stretch: 350,
						depth: 260,
						modifier: 1,
						slideShadows: false,
					},
				},
				1280: {
					coverflowEffect: {
						rotate: 0,
						stretch: 460,
						depth: 260,
						modifier: 1,
						slideShadows: false,
					},
				},
			},
			navigation: {
				nextEl: ".industries-mining-detail.section-3 .btn-next",
				prevEl: ".industries-mining-detail.section-3 .btn-prev",
			},
		}
	);
	/*==================== company ====================*/
	var yearHistory = new Swiper(
		".page-company .wrap-thumb-history .swiper-container",
		{
			slidesPerView: 3,
			spaceBetween: 10,
			autoplay: {
				delay: 1500,
			},
			speed: 300,
			navigation: {
				nextEl: ".page-company .wrap-thumb-history .btn-next",
				prevEl: ".page-company .wrap-thumb-history .btn-prev",
			},
			breakpoints: {
				575: {
					spaceBetween: 60,
					slidesPerView: 5,
				},
				1024: {
					spaceBetween: 30,
					slidesPerView: 6,
				},
				1280: {
					spaceBetween: 30,
					slidesPerView: 11,
				},
			},
		}
	);
	var history = new Swiper(
		".page-company .wrap-main-history .swiper-container",
		{
			spaceBetween: 10,
			speed: 500,
			slidesPerView: 1,
			thumbs: {
				swiper: yearHistory,
			},
		}
	);
	/*==================== Product Detail ====================*/
	var industries_2 = new Swiper(
		".product-pumping-detail.section-5 .swiper-container",
		{
			slidesPerView: 1.4,
			slidesPerGroup: 1,
			speed: 300,
			spaceBetween: 10,
			navigation: {
				nextEl: ".product-pumping-detail.section-5  .btn-next",
				prevEl: ".product-pumping-detail.section-5  .btn-prev",
			},
			breakpoints: {
				575: {
					spaceBetween: 20,
					slidesPerView: 2,
				},
				1024: {
					spaceBetween: 30,
					slidesPerView: 3,
				},
			},
		}
	);
}

$(document).on("scroll", function () {
	header.scrollActive();
});
